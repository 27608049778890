<template>
  <v-container fluid="">

    <v-layout row wrap pr-3 pl-4 pt-4 pb-4 align-center>


      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2 >

        <card name="عدد الحجوزات" icon="fas fa-layer-group" text_color="#53D3F8" :count="counts.ItemsCount"
          :loading="laoding_card[0]"></card>

      </v-flex>


       <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2 >

        <card name="عدد الطلبات" icon="fas fa-layer-group" text_color="#53D3F8" :count="counts.ReserverationsCount"
          :loading="laoding_card[0]"></card>

      </v-flex>




        <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2  >

        <card :name="'عدد اقسام الحجوزات'"  text_color="#6CD6A1" :color="green" :count="counts.CategoryCount" icon="fas fa-border-all"
          ></card>

      </v-flex>



      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2 >

        <card :name="'عدد المستخدمين'" icon="fas fa-users" text_color="#F56D7E" :count="counts.AllUser"
        ></card>

      </v-flex>

<!-- 
      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2   >
           <card :name="' عدد التجار الفعالين'"  icon="fas fa-users" text_color="#f29c2b" :count="counts.owner_on"
          ></card>
      </v-flex> -->





    </v-layout>


       <v-layout row wrap pr-3 pl-4 pt-4 pb-4 align-center>


  
        <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2  >

        <card :name="'عدد التجار'"  text_color="#6CD6A1" :color="green" :count="counts.AllOwner" icon="fas fa-user-secret"
          ></card>

      </v-flex>


       <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2 >

        <card name="عدد التجار المفعلين" icon="fas fa-user-check" text_color="#53D3F8" :count="counts.OwnerActive"
          :loading="laoding_card[0]"></card>

      </v-flex>




        <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2  >

        <card :name="'عدد التجار غير المفعلين'"  text_color="#6CD6A1" :color="green" :count="counts.OwnerUnactive" icon="fas fa-user-clock"
          ></card>

      </v-flex>

      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2 >

        <card :name="'عدد التجار المحظورين'" icon="fas fa-user-times" text_color="#F56D7E" :count="counts.Ownerbloked"
        ></card>

      </v-flex>

<!-- 
      <v-flex xs12 md3 sm3 style="margin-bottom:1%; " pa-2   >
           <card :name="' عدد التجار الفعالين'"  icon="fas fa-users" text_color="#f29c2b" :count="counts.owner_on"
          ></card>
      </v-flex> -->





    </v-layout>



    <!-- <v-layout row wrap pr-3 pl-4>

      <v-flex md6 xs12 style="margin-bottom:1%" pa-1>
        <v-card class="dash_rate_card">
          <p class="dash_part_rate_title">احصائيه حالات الحجز</p>
          <v-divider class="mb-3"></v-divider>






          <div id="chart">
            <apexchart type=pie class="circle_chart" :options="chartOptions" :series="seriess" />
          </div>

        </v-card>
      </v-flex>



      <v-flex md6 sm12 style="padding-left:0%" pa-1 pr-4>
        <v-card style="width:100%;height:500px;border-radius:10px">
          <v-row>
            <p class="dash_part_rate_title">احصائيه سنويه للحجوزات</p>

          </v-row>
          <v-divider class="mb-3"></v-divider>

          <div style="padding-right:10%;padding-left:5%">
            <apexchart type="bar" :options="options" :series="series"></apexchart>
          </div>
        </v-card>

      </v-flex>
    </v-layout> -->

    <!-- display booking details -->

 <v-row>

            <v-data-table :headers="headers" :items="messages" :page.sync="page" @page-count="pageCount = $event"
                sort-by="calories" class="elevation-1 request_table" style="width:96%" items-per-page="15"
                :search="search" hide-default-footer="" :loading="loading" loading-text="جاري تحميل البيانات">

                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>

                        <v-toolbar-title style="font-size:22px">الرسائل</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-row justify="end" style="margin-top:20px;">
                            <div style="width:auto">




                            </div>



                        </v-row>

                    </v-toolbar>
                </template>
                <template v-slot:bottom>
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </template>




            </v-data-table>

        </v-row>









  </v-container>
</template>
<script>
  import card from './Card.vue';

  //import Donut from 'vue-css-donut-chart'
 // import VueApexCharts from 'vue-apexcharts'

  export default {


    data() {
      return {
        dialog: false,
        search: '',
        page: 1,
        messages:[],
        pageCount: 2,
        
        loading: false,
        id: -1,
        item_selected: {},
        loding_accept: false,
        loding_cancel: false,
      
        headers: [{
            text: 'رقم التعريف',
            align: 'center',
            sortable: false,
            value: 'id',
          },
          {
            text: 'اسم الزبون',
            align: 'center',
            sortable: false,
            value: 'name',
          },
          {
            text: 'الايميل',
            align: 'center',
            sortable: false,
            value: 'email',
          },

  
          {
            text: ' نص الرساله',
            value: 'message'
          },


          {
            text: '  تاريخ الرساله',
            value: 'created_at',
            sortable: false
          },


        ],
        reservationCount:'',
        counts: [],
        details_dailog: false,
        items: [],
        token: '',
     
    
       // seriess: [],
        date_select: '',
        menu1: false,
        counts_all:'',
        current_page: 0,
        last_page: 0,
     
        seriess: [33, 10, 40, 55],

        options: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: ['حزيران', 'تموز', 'آب', 'أيلول']
          }
        },

        series: [{
          name: 'series-1',
          data: [153, 200, 100, 400]
        }],
        sections: [

        ],
        getmore: false,
        error_msg: false,
        laoding_card: [false, false, false, false]

      }
    },

    methods: {


 getMsgs() {

        var url ="/operation/contactus"
        // var url = this.urL + "http://127.0.0.1:5555/GetOwnerItemRating?page=" + this.current_page;
        this.loading = true;
        this.$http({
          method: 'get',
          url: url,
          headers: {


          }

        }).then(response => {
          this.messages = response.data.data;

    
        }).catch(error => {
          error
       
        }).finally(s => {
          s;
          this.loading = false;

        });
      },

   
        getCounts() {

                var url = "/operation/getAdminDashbourdCounts";
                this.loading = true;
                this.$http({
                    method: 'get',
                    url: url,
                 

                }).then(response => {
                    this.counts = response.data.data;



                }).catch(error => {
                    error
                    //this.$swal('خطاء', "خطاء بالاتصال", 'error')
                }).finally(s => {
                    s,
                    this.loading = false;
                });
            },
       getCategory() {


                this.$http({
                        method: 'get',
                        url: "/v2/info/Categories",
                    })

                    .then(response => {
                      this.counts.cat_count=response.data.data.length



                    })
            },

      deleteItem(item) {
      //  const index = this.items.indexOf(item)
        this.cancelReservation(item);




      },


    },
    mounted() {

     this.admin_info=this.$cookies.get('admin_details');
    this.getCounts();
      this.getMsgs();
      this.getOWner();

    },

    components: {


      card,
      //apexchart: VueApexCharts,

    }

  }
</script>
